<template>
  <div class="layout-topbar">
    <img
      v-if="empresa.logo"
      :src="'data:image/png;base64, ' + empresa.logo"
      width="100"
      height="60"
      alt="Logo.jpg"
    />
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>
    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <div class="centro-topbar">
      <span class="estilo-fecha"> {{ fechaActual }}</span> <span> &nbsp; </span>
      <span class="estilo-hora">{{ horaActual }}</span>
    </div>

    <!-- Botones de Perfil y Salir -->
    <div class="right-topbar">
      <span class="usuario-name"
        ><strong>Usuario: </strong>{{ user.name }}</span
      >

      <Button
        class="p-button-text p-button-lg p-button-info"
        style="font-size: 1.3rem; font-weight: bold"
        @click="irAPerfil"
        v-tooltip.bottom="'Perfil'"
      >
        <i class="pi pi-fw pi-user"></i>Perfil
      </Button>

      <Button
        class="p-button-text p-button-lg p-button-danger"
        style="font-size: 1.3rem; font-weight: bold"
        @click="openNuevo"
        v-tooltip.bottom="'Salir'"
      >
        <i class="pi pi-fw pi-sign-in"></i>Salir
      </Button>
    </div>
  </div>

  <!-- Dialogo de Cerrar Sesión -->
  <Dialog
    v-model:visible="salirDialog"
    :style="{ width: '450px' }"
    header="Cerrar Sesión"
    :modal="true"
    class="p-fluid"
  >
    <h4><strong>Est&aacute;s seguro de Cerrar Sesión?</strong></h4>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-outlined p-button-success mr-2 mb-2 p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        label="Cerrar Sesión"
        icon="pi pi-check"
        class="p-button-outlined p-button-danger mr-2 mb-2 p-button-lg"
        @click="cerrarSession()"
      />
    </template>
  </Dialog>
</template>

<script>
import { useAuth } from "@/stores";
import EmpresaService from "@/service/EmpresaService";

export default {
  emits: ["menu-toggle"],
  data() {
    return {
      empresa: {},
      user: {},
      salirDialog: false,
      /* nestedMenuitems: [
        {
          label: "Perfil",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Ver Perfil",
              icon: "pi pi-user",
              to: "/usuario-detalle",
            },
          ],
        },
      ], */
      fechaActual: "",
      horaActual: "",
    };
  },
  auth: null,
  empresaService: null,
  beforeCreate() {
    this.empresaService = new EmpresaService();
    this.empresaService.getFirstEmpresa().then((response) => {
      this.empresa = response.data;
    });
  },
  created() {
    this.auth = useAuth();
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000);
  },
  mounted() {
    this.user = { ...this.auth.user };
  },
  methods: {
    irAPerfil() {
      this.$router.push("/usuario-detalle");
    },
    updateDateTime() {
      this.fechaActual = new Date().toLocaleDateString();
      this.horaActual = new Date().toLocaleTimeString();
    },
    openNuevo() {
      this.salirDialog = true;
    },
    ocultarDialog() {
      this.salirDialog = false;
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    } /* 
				topbarImage() {
					return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
				}, */,
    cerrarSession() {
      return this.auth.userLogout();
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style scoped>
.centro-topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.estilo-fecha {
  font-size: 1.3rem;
  font-weight: bold;
}

.estilo-hora {
  font-size: 1.3rem;
}
.right-topbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
}

.usuario-name {
  display: none;
}

/* Mostrar nombre de usuario solo en pantallas grandes */
@media (min-width: 1024px) {
  .usuario-name {
    display: inline;
    margin-right: 10px;
  }
}

/* Ajuste de los botones en pantallas móviles */
@media (max-width: 768px) {
  .right-topbar {
    flex-direction: column;
    align-items: flex-end;
  }

  .right-topbar button {
    margin-bottom: 5px;
  }
}
</style>
